import React from 'react';

import Menu from '../../Components/Menu/menu'

import translation from "../../Components/Helpers/translation";

import styles from './header.module.scss';

function Header() {
	const lang = window.location.pathname.split("/")[1]
	return (
		<div className={styles.wrapper}>
			<div className={`${styles.headerWrapped} ${styles.header}`} id={"header"}>
				<Menu/>
				<p className={styles.mainTitle}>{translation.mainPageTitle}</p>
				<p className={styles.title}>{translation.mainPageSubtitle}</p>
			</div>
			{lang === "en" ? <div className={styles.ueLogoEn}/> : <div className={styles.ueLogoPl}/>}
		</div>
	);
}

export default Header;


